ChatBot.css
.app-container {
    min-height: 100vh;
    position: relative;
  }
  
  .chat-trigger-button {
    position: fixed;
    bottom: 7rem;
    right: 2rem;
    background: #4f46e5;
    color: white;
    border: none;
    border-radius: 50%;
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 4px 12px rgba(79, 70, 229, 0.3);
    transition: all 0.3s ease;
    z-index: 100;
  }
  
  .chat-trigger-button:hover {
    background: #4338ca;
    transform: translateY(-2px);
    box-shadow: 0 6px 16px rgba(79, 70, 229, 0.4);
  }
  
  /* Tooltip styling */
  .tooltip {
    position: fixed;
    bottom: 12rem; /* Increased distance from bottom */
    right: 2rem;
    background-color: #4f46e5;
    color: white;
    padding: 1rem 1.5rem;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    font-size: 14px;
    z-index: 101;
    transition: opacity 0.3s ease;
    animation: fadeIn 0.3s ease-in-out;
  }
  
  .tooltip::after {
    content: '';
    position: absolute;
    bottom: -8px;
    right: 24px; /* Aligns with button center */
    border-width: 8px;
    border-style: solid;
    border-color: #4f46e5 transparent transparent transparent;
  }
  
  /* Tooltip close button styling */
  .tooltip-close-button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    margin-left: 1rem;
    display: flex;
    align-items: center;
  }
  
  .tooltip-close-button:hover {
    color: #cbd5e1;
  }
  
  /* Highlighted effect for chat button*/ 
  .highlighted {
    animation: glow 1.5s ease-in-out infinite alternate;
  }
  
  @keyframes glow {
    from {
      box-shadow: 0 0 10px #4f46e5, 0 0 20px #4f46e5, 0 0 30px #4f46e5;
    }
    to {
      box-shadow: 0 0 20px #4338ca, 0 0 30px #4338ca, 0 0 40px #4338ca;
    }
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @media (max-width: 640px) {
    .modal-content {
      max-height: 100vh;
      height: 100%;
      border-radius: 0;
    }
  
    .modal-overlay {
      padding: 0;
    }
  
    .chat-trigger-button {
      bottom: 1rem;
      right: 1rem;
      width: 48px;
      height: 48px;
    }
  
    .tooltip {
      bottom: 8rem;
      right: 1rem;
    }
  }  


  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent black for the backdrop */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 1rem;
}

.modal-content {
  width: 90%;
  max-width: 600px;
  max-height: 80%;
  background: rgb(186, 226, 243); /* Solid background color */
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  z-index: 1001;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background: #4f46e5; /* Darker blue for header */
    color: white;
    border-bottom: 1px solid #e0f7fa;
}

.modal-title h1 {
    font-size: 1.5rem;
    font-weight: 700; /* Bold */
    margin: 0;
}

.modal-actions .icon-button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
}

.chat-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    overflow-y: auto;
}

.message-area {
    flex: 1;
    overflow-y: auto;
    margin-bottom: 1rem;
    padding: 1rem;
    background: #f9f9f9;
    border-radius: 8px;
}

.message {
    margin-bottom: 1rem;
    padding: 0.75rem;
    border-radius: 8px;
    line-height: 1.5;
}

.user-message {
    background: #d1e7ff;
    align-self: flex-end;
}

.ai-message {
    background: #e8f5e9;
    align-self: flex-start;
}

.input-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.message-input {
    flex: 1;
    padding: 0.5rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 1rem;
}

.send-button {
    background: #4f46e5;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.send-button:hover {
    background: #4338ca;
}

.error-message {
    color: red;
    margin-bottom: 1rem;
    text-align: center;
}
