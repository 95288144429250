.hero {
    position: relative;
    /* background-image: url(https://images.unsplash.com/3/doctype-hi-res.jpg?q=80&w=1730&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D); */
    background-image: url("../../Assets/backgroundimage.webp");
    background-size: cover;
    background-position: center;
  }
  
  .container {
    height: rem(700px);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding-bottom: calc(var(--mantine-spacing-xl) * 6);
    z-index: 1;
    position: relative;
  
    @media (max-width: var(--mantine-breakpoint-sm)) {
      height: rem(500px);
      padding-bottom: calc(var(--mantine-spacing-xl) * 3);
    }
  }
  
  .title {
    color: var(--mantine-color-white);
    font-size: rem(60px);
    font-weight: 900;
    line-height: 1.1;
  
    @media (max-width: var(--mantine-breakpoint-sm)) {
      font-size: rem(40px);
      line-height: 1.2;
    }
  
    @media (max-width: var(--mantine-breakpoint-xs)) {
      font-size: rem(28px);
      line-height: 1.3;
    }
  }
  
  .description {
    color: var(--mantine-color-white);
    max-width: rem(600px);
  
    @media (max-width: var(--mantine-breakpoint-sm)) {
      max-width: 100%;
      font-size: var(--mantine-font-size-sm);
    }
  }
  
  .control {
    margin-top: calc(var(--mantine-spacing-xl) * 1.5);
  
    @media (max-width: var(--mantine-breakpoint-sm)) {
      width: 100%;
    }
  }