.games-carousel-wrapper {
    display: flex;
    justify-content: center; 
    align-items: center;      
    height: 100%;       
}
 
.games-carousel {
    display: inline-flex;    
    gap: 1rem;
    padding: 1rem;
    background-color:'linear-gradient(180deg, #f3e8ff 0%, #ffffff 100%)';
    border-radius: 0.5rem;
    overflow-x: auto;
    scrollbar-width: none;  
    -ms-overflow-style: none;
    white-space: nowrap;      
}
 
.games-carousel::-webkit-scrollbar {
    display: none;
}
 
.game-card {
    position: relative;
    flex-shrink: 0;
    width: 50px;
    height: 300px;
    cursor: pointer;
    border-radius: 0.5rem;
    overflow: hidden;
    transition: all 0.3s ease;
}
 
.game-card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
 
.game-card-overlay {
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
}
 
.game-card-title {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 0.5rem;
    font-size: 0.875rem;
    font-weight: 600;
}
 
.gamepad-icon {
    color: white;
    font-size: 1.5rem;
}
 
@media (max-width: 640px) {
    .games-carousel {
        gap: 0.5rem;
        padding: 0.5rem;
    }
 
    .game-card {
        width: 150px !important;
        height: 84px !important;
    }
}

