/*Modal styles
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 1rem;
}
  
.modal-content {
    background-color: white;
    border-radius: 12px;
    width: 500px;
    max-width: 600px;
    height: 400px;
    max-height: 800px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2);
}
  
Header styles
.modal-header {
    padding: 1rem;
    border-bottom: 1px solid #e5e7eb;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
  
.modal-title {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}
  
.modal-title h1 {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0;
}
  
.title-icon {
    color: #4f46e5;
}
  
.modal-actions {
    display: flex;
    gap: 0.5rem;
}
  
.icon-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
    border-radius: 6px;
    display: flex;
    align-items: center;
    color: #6b7280;
    transition: all 0.2s ease;
}
  
.icon-button:hover {
    background-color: #f3f4f6;
    color: #4f46e5;
}
  
.clear-button {
    font-size: 0.875rem;
    color: #6b7280;
    padding: 0.5rem 1rem;
}
  
/* Chat container styles 
.chat-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
  
.message-area {
    flex: 1;
    overflow-y: auto;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
  
/* Message styles 
.message {
    max-width: 80%;
    padding: 0.75rem 1rem;
    border-radius: 12px;
    position: relative;
}
  
.user-message {
    background-color: #4f46e5;
    color: white;
    align-self: flex-end;
    margin-left: 20%;
}
  
.ai-message {
    background-color: #f3f4f6;
    color: #1f2937;
    align-self: flex-start;
    margin-right: 20%;
}
  
/* Message content styles with smaller font 
.message-content {
    margin: 0;
    line-height: 1.4;
    font-size: 0.875rem;
}
  
.message-content p {
    margin: 0;
    padding: 0;
    font-size: 0.875rem;
}
  
.message-content p + p {
    margin-top: 0.5rem;
}
  
/* Updated timestamp styles - now consistent for both message types 
.message-timestamp {
    display: block;
    font-size: 0.75rem;
    color: #6b7280;
    margin-top: 4px;
    padding-right: 8px;
    text-align: right;
}
  
/* Input container styles 
.input-container {
    padding: 1rem;
    border-top: 1px solid #e5e7eb;
    display: flex;
    gap: 0.75rem;
    align-items: flex-end;
}
  
.message-input {
    flex: 1;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    padding: 0.75rem;
    resize: none;
    font-size: 0.875rem;
    line-height: 1.5;
    min-height: 20px;
    max-height: 200px;
    outline: none;
    transition: border-color 0.2s ease;
}
  
.message-input:focus {
    border-color: #4f46e5;
}
  
.send-button {
    background-color: #4f46e5;
    color: white;
    border: none;
    border-radius: 8px;
    padding: 0.75rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
}
  
.send-button:hover {
    background-color: #4338ca;
}
  
.send-button:disabled {
    background-color: #e5e7eb;
    cursor: not-allowed;
}
  
/* Loading spinner 
.loading-spinner {
    width: 20px;
    height: 20px;
    border: 2px solid #f3f4f6;
    border-top-color: #4f46e5;
    border-radius: 50%;
    animation: spinner 0.8s linear infinite;
}
  
.copy-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #007bff;
    margin-left: 8px;
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 0.75rem;
    padding: 4px 8px;
    border-radius: 4px;
}
  
.copy-button:hover {
    background-color: rgba(0, 123, 255, 0.1);
}
  
@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}
  
/* Error message 
.error-message {
    color: #ef4444;
    padding: 0.5rem 1rem;
    text-align: center;
    font-size: 0.875rem;
}
  
/* Mobile responsive styles 
@media (max-width: 640px) {
    .modal-content {
        height: 100vh;
        max-height: none;
        border-radius: 0;
    }
  
    .modal-overlay {
        padding: 0;
    }
  
    .message {
        max-width: 85%;
    }
}

*/
/* Bold Heading Styles */
.message-content .heading {
    font-weight: bold;
    font-size: 1rem;
  }
  
  /* Existing Message Content Styles */
  .message-content {
    margin: 0;
    line-height: 1.4;
    font-size: 0.875rem;
  }
  
  /* Modal styles */
  .modal-overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
      padding: 1rem;
  }
  
  .modal-content {
      background-color: white;
      border-radius: 12px;
      width: 850px;
      max-width: 700px;
      height: 550px;
      max-height: 800px;
      display: flex;
      flex-direction: column;
      box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2);
  }
  
  /* Header styles */
  .modal-header {
      padding: 1rem;
      border-bottom: 1px solid #e5e7eb;
      display: flex;
      justify-content: space-between;
      align-items: center;
  }
  
  .modal-title {
      display: flex;
      align-items: center;
      gap: 0.5rem;
  }
  
  .modal-title h1 {
      font-size: 1.25rem;
      font-weight: 600;
      margin: 0;
  }
  
  .title-icon {
      color: #4f46e5;
  }
  
  .modal-actions {
      display: flex;
      gap: 0.5rem;
  }
  
  .icon-button {
      background: none;
      border: none;
      cursor: pointer;
      padding: 0.5rem;
      border-radius: 6px;
      display: flex;
      align-items: center;
      color: #6b7280;
      transition: all 0.2s ease;
  }
  
  .icon-button:hover {
      background-color: #f3f4f6;
      color: #4f46e5;
  }
  
  .clear-button {
      font-size: 0.875rem;
      color: #6b7280;
      padding: 0.5rem 1rem;
  }
  
  /* Chat container styles */
  .chat-container {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;
  }
  .message-area {
    flex: 1;
    overflow-y: auto;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* background: rgb(186, 226, 243); */
    /* background: linear-gradient(to bottom, rgb(144, 212, 239), white); Gradient from skyblue to white */
}

  
  /* Message styles */
  .message {
      max-width: 80%;
      padding: 0.75rem 1rem;
      border-radius: 12px;
      position: relative;
  }
  
  .user-message {
    background-color: rgb(138, 212, 246);
      color: white;
      align-self: flex-end;
      margin-left: 20%;
  }
  
  .ai-message {
      background-color: #f3f4f6;
      color: #1f2937;
      align-self: flex-start;
      margin-right: 20%;
  }
  
  /* Message content styles with smaller font */
  .message-content {
      margin: 0;
      line-height: 1.4;
      font-size: 0.875rem;
  }
  
  .message-content p {
      margin: 0;
      padding: 0;
      font-size: 0.875rem;
  }
  
  .message-content p + p {
      margin-top: 0.5rem;
  }
  
  /* Updated timestamp styles - now consistent for both message types */
  .message-timestamp {
      display: block;
      font-size: 0.75rem;
      color: #6b7280;
      margin-top: 4px;
      padding-right: 8px;
      text-align: right;
  }
  
  /* Input container styles */
  .input-container {
    padding: 0.5rem; /* Reduced padding */
    border-top: 1px solid #e5e7eb;
    display: flex;
    gap: 0.5rem; /* Reduced gap */
    align-items: center; /* Adjusted to center the items slightly */
  }
  
  
  .message-input {
    flex: 1;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    padding: 0.5rem; /* Reduced padding */
    resize: none;
    font-size: 1rem; /* Increased font size */
  line-height: 1.0; /* Adjusted line-height for better readability */
    min-height: 18px; 
    max-height: 150px; 
    outline: none;
    transition: border-color 0.2s ease;
  }
  
  
  .message-input:focus {
      border-color: rgb(186, 226, 243);
  }
  
  .send-button {
      background-color: #4f46e5;
      color: white;
      border: none;
      border-radius: 8px;
      padding: 0.75rem;
      cursor: pointer;
  }
  
  .send-button:disabled {
      background-color: #d1d5db;
      cursor: not-allowed;
  }
  
  .clear-button {
      font-size: 0.875rem;
      color: #6b7280;
      padding: 0.5rem 1rem;
  }
/* Style for headings between stars */
.heading {
    font-weight: bold;
    color: black;
  }
    

  .copy-button {
    background: none;
    border: none;
    cursor: pointer;
    color: #007bff;
    margin-left: 8px;
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 0.75rem;
    padding: 4px 8px;
    border-radius: 4px;
}
  
.copy-button:hover {
    background-color: rgba(0, 123, 255, 0.1);
}

.title-icon {
    color: white;
    margin-right: 10px;
    font-size: 24px; /* Adjust the size as needed */
  }
  
  .modal-title h1 {
    color: white; /* Optional: to make the title text white as well */
  }
  